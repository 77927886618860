/* eslint-disable @typescript-eslint/naming-convention */
type Color = 'red' | 'yellow' | 'orange' | 'green' | 'grey' | 'blue';

export const statusColor: {[key in string]: Color} = {
	inactive: 'red',
	active: 'yellow',
	completed: 'green',
	review: 'grey',
};

export const QUALIFICATION_COLORS: {[key in string]: Color} = {
	MEMBER: 'blue',
	QUALIFIED: 'green',
	DISQUALIFIED: 'red',
	NA: 'grey',
};

export type QualificationStatus = 'QUALIFIED' | 'DISQUALIFIED';
export type QualificationStatusMap<T> = {[status in QualificationStatus]: T};
export type DocumentStatusType =
	| 'ERROR'
	| 'PENDING'
	| 'UPDATE_REQUIRED'
	| 'VERIFIED'
	| 'VALIDATED';
export const QUALIFICATION_STATUSES_NAMES: QualificationStatusMap<string> = {
	QUALIFIED: 'qual_status.qualified',
	DISQUALIFIED: 'qual_status.disqualified',
};

export const DISQUALIFICATION_REASONS_NAMES: {[key: string]: string} = {
	other_support: 'qualification.disqualification_reasons.other_support',
	age: 'qualification.disqualification_reasons.age',
	study_gap: 'qualification.disqualification_reasons.study_gap',
	requires_financing:
		'qualification.disqualification_reasons.requires_financing',
	tuition_budget: 'qualification.disqualification_reasons.tuition_budget',
	living_expenses_budget:
		'qualification.disqualification_reasons.living_expenses_budget',
	proof_of_funds: 'qualification.disqualification_reasons.proof_of_funds',
	grades: 'qualification.disqualification_reasons.grades',
	passport: 'qualification.disqualification_reasons.passport',
	document_refusal: 'qualification.disqualification_reasons.document_refusal',
	visa_refusal: 'qualification.disqualification_reasons.visa_refusal',
	app_fee_refusal: 'qualification.disqualification_reasons.app_fee_refusal',
	expired_visa: 'qualification.disqualification_reasons.expired_visa',
	english_proficiency_test:
		'qualification.disqualification_reasons.english_proficiency_test',
	sponsor_support: 'qualification.disqualification_reasons.sponsor_support',
	financing_amount: 'qualification.disqualification_reasons.financing_amount',
	financially_prepared:
		'qualification.disqualification_reasons.financially_prepared',
	sponsor_relationship:
		'qualification.disqualification_reasons.sponsor_relationship',
	td_funds_available:
		'qualification.disqualification_reasons.td_funds_available',
	immigration_pof: 'qualification.disqualification_reasons.immigration_pof',
};

export const QUALIFICATION_STATUSES = [
	...(Object.keys(
		QUALIFICATION_STATUSES_NAMES,
	) as (keyof typeof QUALIFICATION_STATUSES_NAMES)[]),
];

export type LevelOfStudy =
	| 'BACHELORS'
	| 'MASTERS'
	| 'UNDERGRAD_DIPLOMA'
	| 'POSTGRAD_DIPLOMA'
	| 'UNDERGRAD_CERTIFICATE'
	| 'POSTGRAD_CERTIFICATE'
	| 'ASSOCIATE_DEGREE';

export const STUDY_LEVELS: {[key in LevelOfStudy]: string} = {
	BACHELORS: 'study_levels.bachelors',
	MASTERS: 'study_levels.masters',
	UNDERGRAD_DIPLOMA: 'study_levels.undergrad_diploma',
	POSTGRAD_DIPLOMA: 'study_levels.postgrad_diploma',
	UNDERGRAD_CERTIFICATE: 'study_levels.undergrad_certificate',
	POSTGRAD_CERTIFICATE: 'study_levels.postgrad_certificate',
	ASSOCIATE_DEGREE: 'study_levels.associate_degree',
};

export const ACCEPTED_EP_TEST: {[key: string]: string} = {
	IELTS: 'english_test.ielts',
	TOEFL: 'english_test.toefl',
	PTE: 'english_test.pte',
	DUOLINGO: 'english_test.duolingo',
};

export type EnglishProficiencyTest =
	| 'IELTS'
	| 'TOEFL'
	| 'PTE'
	| 'DUOLINGO'
	| 'CAMBRIDGE'
	| 'OET';

export const ENGLISH_PROFICIENCY_TEST: {
	[key in EnglishProficiencyTest]: string;
} = {
	IELTS: 'english_test.ielts',
	TOEFL: 'english_test.toefl',
	PTE: 'english_test.pte',
	DUOLINGO: 'english_test.duolingo',
	OET: 'english_test.oet',
	CAMBRIDGE: 'english_test.cambridge',
};

export const BOOLEAN = {
	Yes: true,
	No: false,
};

export const BOOLEAN_STRING = {
	true: 'yes',
	false: 'no',
};

export type PassportStatus = 'YES' | 'NO' | 'APPLIED';

export const PASSPORT_STATUS: {[key in PassportStatus]: string} = {
	YES: 'passport_status.yes',
	NO: 'passport_status.no',
	APPLIED: 'passport_status.applied',
};

export type SponsorRelationship =
	| 'PARENT'
	| 'SIBLING'
	| 'UNCLE'
	| 'OTHER_FAMILY'
	| 'FRIEND';

export const SPONSOR_RELATIONSHIPS: {[key in SponsorRelationship]: string} = {
	PARENT: 'sponsor_relationships.parent',
	SIBLING: 'sponsor_relationships.sibling',
	UNCLE: 'sponsor_relationships.uncle',
	OTHER_FAMILY: 'sponsor_relationships.other_family',
	FRIEND: 'sponsor_relationships.friend',
};

export type SponsorSupport = 'TUITION' | 'TUITION_DEPOSIT' | 'PROOF_OF_FUNDS';

export const SPONSOR_SUPPORT: {[key in SponsorSupport]: string} = {
	TUITION: 'sponsor_support.tuition',
	TUITION_DEPOSIT: 'sponsor_support.tuition_deposit',
	PROOF_OF_FUNDS: 'sponsor_support.proof_of_funds',
};

export type FinancingAmount = 'ALL' | 'HALF' | 'MINIMAL';

export const FINANCING_AMOUNT: {[key in FinancingAmount]: string} = {
	ALL: 'financing_amount.all',
	HALF: 'financing_amount.half',
	MINIMAL: 'financing_amount.minimal',
};

export const ONSHORE_OFFSHORE = {
	Onshore: true,
	Offshore: false,
};

export type CoachingFunnelStageMap<T> = {[stage in CoachingFunnelStage]: T};
export type FunnelStageMap<T> = {[stage in FunnelStage]: T};

export const COACHING_FUNNEL_STAGE_NAMES: CoachingFunnelStageMap<string> = {
	GET_STARTED: 'students.funnel_stages.get_started',
	DISCOVER: 'students.funnel_stages.discover',
	PREP: 'students.funnel_stages.prep',
	SUBMIT: 'students.funnel_stages.submit',
	ACCEPTED: 'students.funnel_stages.accepted',
	VISA: 'students.funnel_stages.visa',
	SET_UP: 'students.funnel_stages.set_up',
	PLACED: 'students.funnel_stages.placed',
};

export const FUNNEL_STAGE_NAMES: FunnelStageMap<string> = {
	SIGNUP: 'students.funnel_stages.signup',
	VERIFICATION: 'students.funnel_stages.verification',
	...COACHING_FUNNEL_STAGE_NAMES,
};

export const STUDENT_DOCUMENT_TYPE_NAMES: {
	[type in StudentDocumentType]: string;
} = {
	PASSPORT: 'documents.types.passport',
	G10_TRANSCRIPT: 'documents.types.g10_transcript',
	G11_TRANSCRIPT: 'documents.types.g11_transcript',
	G12_TRANSCRIPT: 'documents.types.g12_transcript',
	BACHELORS_TRANSCRIPT: 'documents.types.bachelors_transcript',
	DIPLOMA_TRANSCRIPT: 'documents.types.diploma_transcript',
	G10_CERTIFICATE: 'documents.types.g10_certificate',
	G11_CERTIFICATE: 'documents.types.g11_certificate',
	HIGH_SCHOOL_GRADUATION_CERTIFICATE:
		'documents.types.high_school_graduation_certificate',
	BACHELORS_GRADUATION_CERTIFICATE:
		'documents.types.bachelors_graduation_certificate',
	IELTS: 'documents.types.ielts',
	TOEFL: 'documents.types.toefl',
	DUOLINGO: 'documents.types.duolingo',
	PTE: 'documents.types.pte',
	CAMBRIDGE: 'documents.types.cambridge',
	OET: 'documents.types.oet',
	FINANCIAL_DOCUMENTS: 'documents.types.financial_documents',
	PROOF_OF_NAME_CHANGE: 'documents.types.proof_of_name_change',
	ENGLISH_PROFICIENCY_TEST_EXEMPTION:
		'documents.types.english_proficiency_test_exemption',
	PROOF_OF_VACCINE: 'documents.types.proof_of_vaccine',
	STATEMENT_OF_PURPOSE: 'documents.types.statement_of_purpose',
	RESUME: 'documents.types.resume',
	STUDY_GAP_EXPLANATION: 'documents.types.study_gap_explanation',
	LETTER_OF_REFERENCE: 'documents.types.letter_of_reference',
	AUTHORIZATION_FORM: 'documents.types.authorization_form',
	DECLARATION_FORM: 'documents.types.declaration_form',
	PROGRAM_ESSAY: 'documents.types.program_essay',
	COMPLETE_APPLICATION_PACKAGE: 'documents.types.complete_application_package',
	LETTER_OF_ACCEPTANCE: 'documents.types.letter_of_acceptance',
	LETTER_OF_ENROLLMENT: 'documents.types.letter_of_enrollment',
	STUDY_PERMIT_LETTER: 'documents.types.study_permit_letter',
	STUDY_VISA: 'documents.types.study_visa',
	REJECTION_LETTER: 'documents.types.rejection_letter',
	WAITLIST: 'documents.types.waitlist',
	OTHER: 'documents.types.other',
};

export const STUDENT_STATUSES_SLUG: {
	[key: string]: CoachingFunnelStage | 'ARCHIVED';
} = {
	start: 'GET_STARTED',
	discover: 'DISCOVER',
	prep: 'PREP',
	apply: 'SUBMIT',
	accepted: 'ACCEPTED',
	visa: 'VISA',
	arrive: 'SET_UP',
	placed: 'PLACED',
	archived: 'ARCHIVED',
};

export type StudentGroup = 'ONSHORE' | 'OFFSHORE' | 'SDS' | 'ENGLISH_EXEMPT';

export const STUDENT_GROUP_TYPES: {[key in StudentGroup]: string} = {
	ONSHORE: 'students.groups.onshore',
	OFFSHORE: 'students.groups.offshore',
	SDS: 'students.groups.sds',
	ENGLISH_EXEMPT: 'students.groups.english_exempt',
};

export type Partner =
	| 'SUB_AGENT'
	| 'BRAND_AMBASSADOR'
	| 'INFLUENCER'
	| 'SCHOOL_PARTNER'
	| 'BUSINESS_PARTNER'
	| 'OTHER';

export const PARTNER_TYPES: {[key in Partner]: string} = {
	SUB_AGENT: 'partner_types.sub_agent',
	BRAND_AMBASSADOR: 'partner_types.brand_ambassador',
	INFLUENCER: 'partner_types.influencer',
	SCHOOL_PARTNER: 'partner_types.school_partner',
	BUSINESS_PARTNER: 'partner_types.business_partner',
	OTHER: 'partner_types.other',
};

export const GENERAL_DOCUMENT_TYPES: {[type in GeneralDocumentType]: string} = {
	PASSPORT: 'Passport',
	G10_TRANSCRIPT: 'Grade 10 Transcript',
	G11_TRANSCRIPT: 'Grade 11 Transcript',
	G12_TRANSCRIPT: 'Grade 12 Transcript',
	BACHELORS_TRANSCRIPT: 'Bachelors Transcript',
	DIPLOMA_TRANSCRIPT: 'Diploma Transcript',
	G10_CERTIFICATE: 'Grade 10 Certificate',
	G11_CERTIFICATE: 'Grade 11 Certificate',
	HIGH_SCHOOL_GRADUATION_CERTIFICATE: 'High School Graduation Certificate',
	BACHELORS_GRADUATION_CERTIFICATE: 'Bachelors Graduation Certificate',
	IELTS: 'IELTS English Proficiency Test',
	TOEFL: 'TOEFL English Proficiency Test',
	DUOLINGO: 'Duolingo English Proficiency Test',
	PTE: 'PTE English Proficiency Test',
	CAMBRIDGE: 'Cambridge English Proficiency Test',
	OET: 'OET English Proficiency Test',
	FINANCIAL_DOCUMENTS: 'Financial Documents',
	PROOF_OF_NAME_CHANGE: 'Proof of Name Change',
	ENGLISH_PROFICIENCY_TEST_EXEMPTION: 'English Proficiency Test Exemption',
	RESUME: 'Resume',
};

export const APPLICATION_STATUS_DOCUMENT: {
	[key in ApplicationStatusDocument]: string;
} = {
	REJECTION_LETTER: 'Rejection Letter',
	WAITLIST: 'Waitlist',
};

export const APPLICATION_DOCUMENT_TYPES: {
	[type in ApplicationDocumentType]: string;
} = {
	PROOF_OF_VACCINE: 'Proof of Vaccine',
	STATEMENT_OF_PURPOSE: 'Statement of Purpose',
	STUDY_GAP_EXPLANATION: 'Study Gap Explanation',
	LETTER_OF_REFERENCE: 'Letter of Reference',
	AUTHORIZATION_FORM: 'Authorization Form',
	DECLARATION_FORM: 'Declaration Form',
	PROGRAM_ESSAY: 'Program Essay',
	COMPLETE_APPLICATION_PACKAGE: 'Complete Application Package',
	...APPLICATION_STATUS_DOCUMENT,
	OTHER: 'Other',
};

export const ONSHORE_DOCUMENT_TYPES: {[type in OnshoreDocumentType]: string} = {
	STUDY_PERMIT_LETTER: 'Study Permit Letter',
	STUDY_VISA: 'Study Visa',
	LETTER_OF_ACCEPTANCE: 'Letter of Acceptance',
	LETTER_OF_ENROLLMENT: 'Letter of Enrollment',
};

export const DOCUMENTS = {
	...GENERAL_DOCUMENT_TYPES,
	...APPLICATION_DOCUMENT_TYPES,
	...ONSHORE_DOCUMENT_TYPES,
};

const {ENGLISH_PROFICIENCY_TEST_EXEMPTION, ...REQUIRED_GENERAL_DOCUMENT_TYPES} =
	GENERAL_DOCUMENT_TYPES;

export const REQUIRED_DOCUMENTS: {
	[type in RequiredStudentDocumentType]: string;
} = {
	...REQUIRED_GENERAL_DOCUMENT_TYPES,
	...APPLICATION_DOCUMENT_TYPES,
	...ONSHORE_DOCUMENT_TYPES,
};

export const CURRENT_MAJOR_TYPES: {[key: string]: string} = {
	Business: 'Business',
	Sciences: 'Sciences',
	Engineering: 'Engineering',
	'Math, Engineering, Computer Science': 'Math, Engineering & Computer Science',
	'Creative Arts': 'Creative Arts',
	'Social Studies': 'Social Studies',
	'Social Studies & Humanities': 'Social Studies & Humanities',
	Education: 'Education',
	'Skilled Trades': 'Skilled Trades',
	Other: 'Other',
};

export type ProgressCardStages = Extract<
	FunnelStage,
	| 'GET_STARTED'
	| 'DISCOVER'
	| 'PREP'
	| 'ACCEPTED'
	| 'VISA'
	| 'SET_UP'
	| 'PLACED'
>;

export const PROGRESS_STEP_NAMES: {[key in ProgressCardStages]: string} = {
	GET_STARTED: 'Get Started',
	DISCOVER: 'Discover Programs',
	PREP: 'Prep Applications',
	ACCEPTED: 'Track Offers',
	VISA: 'Apply for Visa',
	SET_UP: 'Set Up',
	PLACED: 'Land on Campus',
};

export const PROGRESS_STEPS = [
	...(Object.keys(PROGRESS_STEP_NAMES) as (keyof typeof PROGRESS_STEP_NAMES)[]),
];
export type ProgressStep = (typeof PROGRESS_STEPS)[number];

export type StudentDocumentMap<T> = {[type in StudentDocumentType]: T};

export const STUDENT_DOCUMENT_COMPLETE_TYPES: StudentDocumentMap<string> = {
	...GENERAL_DOCUMENT_TYPES,
	...APPLICATION_DOCUMENT_TYPES,
	...ONSHORE_DOCUMENT_TYPES,
};

type OnshoreStudentDocumentMap<T> = {
	[type in GeneralDocumentType | OnshoreDocumentType]: T;
};

export type OnshoreStudentDocumentType =
	| GeneralDocumentType
	| OnshoreDocumentType;

export const ONSHORE_STUDENT_DOCUMENT_TYPES: OnshoreStudentDocumentMap<string> =
	{
		...GENERAL_DOCUMENT_TYPES,
		...ONSHORE_DOCUMENT_TYPES,
	};

export const STUDENT_DOCUMENT_STATUS_NAMES: {[key: string]: string} = {
	PENDING: 'students.documents.status.pending',
	VERIFIED: 'students.documents.status.verified',
};

export const STUDENT_DOCUMENT_STATUSES = [
	...Object.keys(STUDENT_DOCUMENT_STATUS_NAMES),
];
export type StudentDocumentStatusType =
	(typeof STUDENT_DOCUMENT_STATUSES)[number];

export const COACHING_FUNNEL_STAGES = [
	...(Object.keys(
		COACHING_FUNNEL_STAGE_NAMES,
	) as (keyof typeof COACHING_FUNNEL_STAGE_NAMES)[]),
];

export const FUNNEL_STAGES = [
	...(Object.keys(FUNNEL_STAGE_NAMES) as (keyof typeof FUNNEL_STAGE_NAMES)[]),
];

export type CoachingPipeline =
	| 'VERIFICATION_MESSAGING'
	| 'VERIFICATION_CALLING'
	| 'VERIFICATION_MESSAGING_ONSHORE'
	| 'VERIFICATION_CALLING_ONSHORE'
	| 'VERIFICATION_AUTO_CALLING'
	| 'COACHING';

export type CoachingPipelineMap<T> = {[pipeline in CoachingPipeline]: T};

export const COACHING_PIPELINE_NAMES: CoachingPipelineMap<string> = {
	VERIFICATION_MESSAGING: 'coach.pipelines.verification_messaging',
	VERIFICATION_CALLING: 'coach.pipelines.verification_calling',
	VERIFICATION_MESSAGING_ONSHORE:
		'coach.pipelines.verification_messaging_onshore',
	VERIFICATION_CALLING_ONSHORE: 'coach.pipelines.verification_calling_onshore',
	VERIFICATION_AUTO_CALLING: 'coach.pipelines.verification_auto_calling',
	COACHING: 'coach.pipelines.coaching',
};

export const COACHING_PIPELINES = [
	...(Object.keys(
		COACHING_PIPELINE_NAMES,
	) as (keyof typeof COACHING_PIPELINE_NAMES)[]),
];

export const STUDENT_DOCUMENT_TYPES = [
	...Object.keys(GENERAL_DOCUMENT_TYPES),
	...Object.keys(APPLICATION_DOCUMENT_TYPES),
	...Object.keys(ONSHORE_DOCUMENT_TYPES),
] as const;

export type FunnelStage =
	| 'SIGNUP'
	| 'VERIFICATION'
	| 'GET_STARTED'
	| 'DISCOVER'
	| 'PREP'
	| 'SUBMIT'
	| 'ACCEPTED'
	| 'VISA'
	| 'SET_UP'
	| 'PLACED';

export type CoachingFunnelStage = Exclude<
	FunnelStage,
	'SIGNUP' | 'VERIFICATION'
>;

export type ApplicationStatusDocument = 'REJECTION_LETTER' | 'WAITLIST';

export type ApplicationDocumentType =
	| 'PROOF_OF_VACCINE'
	| 'STATEMENT_OF_PURPOSE'
	| 'STUDY_GAP_EXPLANATION'
	| 'LETTER_OF_REFERENCE'
	| 'AUTHORIZATION_FORM'
	| 'DECLARATION_FORM'
	| 'PROGRAM_ESSAY'
	| 'COMPLETE_APPLICATION_PACKAGE'
	| ApplicationStatusDocument
	| 'OTHER';

export type ApplicationProfileStepType =
	| 'NOT_STARTED'
	| 'PERSONAL_INFORMATION'
	| 'ADDRESS_DETAILS'
	| 'EDUCATION_SUMMARY'
	| 'ENGLISH_TEST_SCORE'
	| 'BACKGROUND_INFORMATION'
	| 'EMERGENCY_CONTACT'
	| 'COMPLETED';

export const APPLICATION_PROFILE_STAGES: ApplicationProfileStepType[] = [
	'NOT_STARTED',
	'PERSONAL_INFORMATION',
	'ADDRESS_DETAILS',
	'EDUCATION_SUMMARY',
	'ENGLISH_TEST_SCORE',
	'BACKGROUND_INFORMATION',
	'EMERGENCY_CONTACT',
	'COMPLETED',
];

export type GeneralDocumentType =
	| 'PASSPORT'
	| 'G10_TRANSCRIPT'
	| 'G11_TRANSCRIPT'
	| 'G12_TRANSCRIPT'
	| 'BACHELORS_TRANSCRIPT'
	| 'DIPLOMA_TRANSCRIPT'
	| 'G10_CERTIFICATE'
	| 'G11_CERTIFICATE'
	| 'HIGH_SCHOOL_GRADUATION_CERTIFICATE'
	| 'BACHELORS_GRADUATION_CERTIFICATE'
	| 'IELTS'
	| 'TOEFL'
	| 'DUOLINGO'
	| 'PTE'
	| 'CAMBRIDGE'
	| 'OET'
	| 'FINANCIAL_DOCUMENTS'
	| 'PROOF_OF_NAME_CHANGE'
	| 'RESUME'
	| 'ENGLISH_PROFICIENCY_TEST_EXEMPTION';

export type OnshoreDocumentType =
	| 'LETTER_OF_ACCEPTANCE'
	| 'LETTER_OF_ENROLLMENT'
	| 'STUDY_PERMIT_LETTER'
	| 'STUDY_VISA';

export type StudentDocumentType =
	| GeneralDocumentType
	| ApplicationDocumentType
	| OnshoreDocumentType;

export type RequiredStudentDocumentType = Exclude<
	StudentDocumentType,
	'ENGLISH_PROFICIENCY_TEST_EXEMPTION'
>;
export type MaritalStatus = 'SINGLE' | 'MARRIED';

export const MARITAL_STATUS_NAMES: {[key in MaritalStatus]: string} = {
	SINGLE: 'application_profile.personal_information.marital_statuses.single',
	MARRIED: 'application_profile.personal_information.marital_statuses.married',
};

export type Gender = 'MALE' | 'FEMALE';

export const GENDER_NAMES: {[key in Gender]: string} = {
	MALE: 'application_profile.personal_information.genders.male',
	FEMALE: 'application_profile.personal_information.genders.female',
};

export const ONSHORE_TYPE_NAMES: {[key in OnshoreType]: string} = {
	EXTENDER: 'onshore_type.extender',
	SWITCHER: 'onshore_type.switcher',
};

export type OnshoreType = 'EXTENDER' | 'SWITCHER';

export interface Currency {
	amount: number;
	currency: string;
}

export const COST_BY_STUDY_LEVEL: {[key in LevelOfStudy]: string} = {
	MASTERS: '50,780',
	POSTGRAD_DIPLOMA: '47,730',
	POSTGRAD_CERTIFICATE: '45,230',
	UNDERGRAD_DIPLOMA: '44,230',
	UNDERGRAD_CERTIFICATE: '44,230',
	BACHELORS: '48,780',
	ASSOCIATE_DEGREE: '48,780',
};
