import {
	ApiClientProvider,
	AbsintheChannelProvider,
	SocketProvider,
} from '@halp/api';
import {ToastProvider} from '@halp/ui';
import {UserContext, UserProvider} from '../../Users';
import type {SessionUser} from '../../Users';
import type {ReactNode} from 'react';

interface Props {
	children: ReactNode;
	logoutMessage: string;
	dehydratedState: unknown;
	onUserLoaded?: (user: SessionUser) => void;
}

export function AppProvider({children, dehydratedState, ...props}: Props) {
	return (
		<ToastProvider>
			<ApiClientProvider dehydratedState={dehydratedState}>
				<UserProvider {...props}>
					<UserContext.Consumer>
						{(value) => (
							<SocketProvider
								url="/graphql-socket"
								options={{params: {token: value?.user?.token || null}}}
							>
								<AbsintheChannelProvider>{children}</AbsintheChannelProvider>
							</SocketProvider>
						)}
					</UserContext.Consumer>
				</UserProvider>
			</ApiClientProvider>
		</ToastProvider>
	);
}
