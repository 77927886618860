import {createContext, useContext, useEffect, useState} from 'react';
import {SocketContext} from '../../websockets/SocketProvider';
import type {Channel} from 'phoenix';

export const AbsintheChannelContext = createContext<Channel | null>(null);

// Provides the Absinthe control channel, which is responsible for receiving GraphQL subscription documents
// and sending back a channel id for that subscription so the client can receive data.
export function AbsintheChannelProvider({
	children,
}: {
	children: React.ReactNode;
}) {
	const [absintheControlChannel, setAbsintheControlChannel] =
		useState<Channel | null>(null);

	const socket = useContext(SocketContext);

	useEffect(() => {
		if (!socket) return;
		const absintheControlChannel = socket.channel('__absinthe__:control');
		absintheControlChannel?.join();
		setAbsintheControlChannel(absintheControlChannel || null);

		return () => {
			absintheControlChannel?.leave();
			setAbsintheControlChannel(null);
		};
	}, [socket]);

	return (
		<AbsintheChannelContext.Provider value={absintheControlChannel}>
			{children}
		</AbsintheChannelContext.Provider>
	);
}
